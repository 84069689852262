/* eslint-disable max-len */

import type { ApiLangPack } from '../api/types';

export const fallbackLangPackInitial = {
  WrongNumber: 'Неверный номер?',
  SentAppCode: 'Мы отправили код в приложение Telegram на другом Вашем устройстве.',
  'Login.JustSentSms': 'Мы отправили код в CМС',
  'Login.Header.Password': 'Введите пароль',
  'Login.EnterPasswordDescription': 'Вы включили двухэтапную аутентификацию. Ваш аккаунт защищён дополнительным облачным паролем.',
  StartText: 'Проверьте код страны и введите свой номер телефона.',
  'Login.PhonePlaceholder': 'Номер телефона',
  'Login.Next': 'Далее',
  'Login.QR.Login': 'Быстрый вход по QR-коду',
  'Login.QR.Title': 'Быстрый вход по QR-коду',
  'Login.QR.Help1': 'Откройте телеграм с телефона',
  'Login.QR.Help2': 'Перейдите в Настройки > Устройства > Подключить устройство',
  'Login.QR2.Help2': 'Перейдите в Настройки > Устройства > Подключить устройство',
  'Login.QR.Help3': 'Для подтверждения направьте камеру телефона на этот экран',
  'Login.QR.Cancel': 'Вход по номеру телефона',
  YourName: 'Имя',
  'Login.Register.Desc': 'Enter your name and add a profile picture.',
  'Login.Register.FirstName.Placeholder': 'First Name',
  'Login.Register.LastName.Placeholder': 'Last Name',
  'Login.SelectCountry.Title': 'Страна',
  lng_country_none: 'Страна не найдена',
  PleaseEnterPassword: 'Введите  новый пароль',
  PHONE_NUMBER_INVALID: 'Неверный номер телефона',
  PHONE_CODE_INVALID: 'Неверный код',
  PASSWORD_HASH_INVALID: 'Неверный пароль',
  PHONE_PASSWORD_FLOOD: 'Лимит превышен. Попробуйте снова позже.',
  PHONE_NUMBER_BANNED: 'Этот номер телефона забанен.',
} as ApiLangPack;
